(function ($) {

	/** 
	 * Select Costumizado
	 * http://jsfiddle.net/tovic/ZTHkQ/
	 */

	var Select = function( options ) {

		// Scope Fix
		var _this = this;

		// Init
		this.init = function() {
			// Selector
			_this.$select = options.selector;
			// Construir select
			_this.build();
			// Chamar binds
			_this.binds();
			// Se existir a propriedade callback no objeto de opções e se for função
			if( typeof options !== 'undefined' && options.hasOwnProperty( 'callback' ) && typeof options.callback === 'function' ) {
				// Executar callback
				options.callback( options.selector );
			}
		};

		// Binds
		this.binds = function() {
			// Se houver selects
			if( _this.$select.length > 0 ) {
				// Click no select
				_this.$customSelect.on( 'click', _this.toggleSelect );
				// Click na opção do select
				_this.$customSelectList.children( 'li' ).on( 'click', _this.selectOption );
				// Quando clicamos no documento, escondemos o select
				$( document ).on( 'click', _this.hideSelect );
			}
		};

		// Toggle Select
		this.toggleSelect = function( event ) {
			// Escondemos todos os outros select
			$( '.select-wrap.active' ).each(function() {
				$( this ).removeClass( 'active' );
			});
			// Mostrar este select
			_this.$selectWrap.toggleClass( 'active' );
			event.stopPropagation();
		};

		// Hide Select
		this.hideSelect = function( event ) {
			_this.$selectWrap.removeClass( 'active' );
		};

		// Select Option
		this.selectOption = function( event ) {
			// Remover class de active e colocar o texto
			_this.$customSelect.text( $( this ).text() );
			_this.$selectWrap.removeClass( 'active' );
			// Colocar o valor no <select>
			var selectValue = $( this ).data( 'select-value' );
			_this.$select.val( selectValue ).change();
			// Colocar class de como o select tem valor
			_this.$selectWrap[ selectValue.length > 0 || typeof selectValue === 'number' ? 'addClass' : 'removeClass' ]( 'has-value' );
			// Active na <option>
			$( this ).addClass( 'active' ).siblings().removeClass( 'active' );
			// Chamar callback
			if( typeof options !== 'undefined' && options.hasOwnProperty( 'onSelect' ) && typeof options.onSelect === 'function' ) {
				// Executar callback
				options.onSelect( options.selector, selectValue );
			}
			event.stopPropagation();
		};

		// Build
		this.build = function() {
			// Se houver selects
			if( _this.$select.length > 0 ) {
				// Vars
				var $selectOptions = _this.$select.children( 'option' ), // Apanhar as opções do select
				numberOfOptions = $selectOptions.length; // Apanhar o número de opções
				// Esconder o select
				_this.$select.addClass( 'select-original d-none' );
				// Wrap no select
				_this.$select.wrap( '<div class="select-wrap"></div>' );
				_this.$selectWrap = _this.$select.parent();
				// Inserir o nosso select
				_this.$select.after( '<span class="select-current"></span>' );
				// Buscar o nosso select
				_this.$customSelect = _this.$select.next( '.select-current' );
				// Buscar opção selecionada
				var $selectedOption = ( $selectOptions.filter( ':selected' ).length > 0 ) ? $selectOptions.filter( ':selected' ) : $selectOptions.eq( 0 ); // Buscar a opção seleccionada
				// Colocar a opção selecionada como texto (improvisar o seleccionado)
				_this.$customSelect.text( $selectedOption.text() );
				// Inserir a lista dos items
				_this.$customSelectList = $( '<ul />', {
					'class': 'select-options'
				}).insertAfter( _this.$customSelect );
				// Inserir na lista as opções do select
				$selectOptions.each(function( index, el ) {
					// Buscar a label da opção
					var optionLabel = $( this ).text(),
						optionValue = $( this ).val(),
						optionClass = ( $( this ).is( ':selected' ) ) ? ' active' : '';
					// Colocar na lista
					_this.$customSelectList.append( '<li class="select-option' + optionClass + '" data-select-value="' + optionValue + '"><span>' + optionLabel + '</span></li>' );
				});
			}
		};

	};

	/**
	 * Relógio
	 * @see https://codepen.io/ky0suke/pen/MyNXWX
	 */

	var Clock = function( $el, offset ) {

		var _this = this;

		/**
		 * Init
		 */

		this.init = function() {
			_this.hourHand = $el.find( '.clock-hour' );
			_this.minuteHand = $el.find( '.clock-minute' );
			_this.secondHand = $el.find( '.clock-second' );
			_this.timer();
			setInterval( $.proxy( _this.timer, _this ), 1000 );
		};

		/**
		* Timer
		*/

		this.timer = function() {
			_this.sethandRotation( 'hour' );
			_this.sethandRotation( 'minute' );
			_this.sethandRotation( 'second' );
		};

		/**
		 * Mudar rotação dos ponteiros
		 */

		this.sethandRotation = function( hand ) {
			var date = _this.convertDateAsUTC( new Date() ),
				hours,
				minutes, 
				seconds, 
				percentage, 
				degree;

			switch( hand ) {
				case 'hour':
					hours = date.getHours();
					hand = _this.hourHand;
					percentage = _this.numberToPercentage( hours, 12 );
				break;
				case 'minute':
					minutes = date.getMinutes();
					hand = _this.minuteHand;
					percentage = _this.numberToPercentage( minutes, 60 );
				break;
				case 'second':
					seconds = date.getSeconds();
					hand = _this.secondHand;
					percentage = _this.numberToPercentage( seconds, 60 );
				break;
			}

			degree = _this.percentageToDegree(percentage);
			hand.css( 'transform', 'rotate(' + degree + 'deg) translate(-50%, -50%)' );
		};

		/**
		 * Converter número para percentagem
		 */

		this.numberToPercentage = function( number, max ) {
			return ( number / max ) * 100;
		};

		/**
		 * Converter percentagem para ângulo
		 */

		this.percentageToDegree = function( percentage ) {
			return ( percentage * 360 ) / 100;
		};

		/**
		 * Converter data
		 */

		this.convertDateAsUTC = function( date ) {
			return new Date( Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + $el.data( 'clock-offset' ), date.getMinutes(), date.getSeconds() ) ); 
		};

		this.init();

	};

	var slickDefaults = {
		rows: 0,
		speed: 500,
		arrows: false,
		dots: false,
		draggable: false,
		adaptiveHeight: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		cssEase: 'cubic-bezier(0.77, 0, 0.175, 1)',
		slide: '.slide-item',
		prevArrow: '<button type="button" class="slick-prev slick-arrow"><i class="fas fa-chevron-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow"><i class="fas fa-chevron-right"></i></button>'
	};

	/**
	 * Disparar código de acordo com a página
	 */

	var JTM = {
		'common': {
			init: function() {

				/**
				 * Relógios
				 */

				$( '.clock' ).each(function( index, el ) {
					var clock = new Clock( $( this ) );
				});

				/**
				 * Slider de Logos
				 */

				 $( '.logo-list-slider' ).slick( $.extend( {}, slickDefaults, {
					slidesToShow: 10,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 3000,
					responsive: [
						{ breakpoint: 640, settings: { slidesToShow: 2 } },
						{ breakpoint: 880, settings: { slidesToShow: 4 } },
						{ breakpoint: 1200, settings: { slidesToShow: 6 } },
						{ breakpoint: 1400, settings: { slidesToShow: 10 } }
					]
				}));

				/**
				 * Header
				 */

				var $mainHeader = $( '#main-header' ),
					$mainNavModal = $( '#main-nav-modal' ),
					$mainNavModalBtn = $( '[data-target="#main-nav-modal"]' );

				$(window).on( 'load scroll', function() {
					// Buscar a distância do topo da janela ao topo do documento
					var scrollTop = $( this ).scrollTop(),
						triggerTop = $mainHeader.outerHeight();
					// Ativamos / Desactivamos a class dependendo se o scroll top passa a posição do trigger
					$mainHeader[ scrollTop >= triggerTop ? 'addClass' : 'removeClass' ]( 'is-sticky' );
				});

				// Ao clicar no botão do menu
				$mainHeader.on( 'click', '.toggle-menu', function( event ) {
					$( this ).add( $( this ).siblings( '.menu-nav' ) ).toggleClass( 'active' );
					// Prevenir comportamento por defeito
					event.preventDefault();
				});

				$mainNavModal.on( 'show.bs.modal hide.bs.modal', function( event ) {
					if( event.type === 'show' ) {
						$( 'body' ).addClass( 'main-nav-modal-open' );
						$mainNavModalBtn.html( jtmAjaxObject.menuLabelClose );
					} else {
						$( 'body' ).removeClass( 'main-nav-modal-open' );
						$mainNavModalBtn.html( jtmAjaxObject.menuLabelOpen );
					}
				});

				/**
				 * Search
				 */

				$( '.toggle-search, .close-search' ).on( 'click', function( event ) {
					// Adicionar class de activo
					$( '.form-search' ).toggleClass( 'active' );
					// Ativar / desactivar class no body para prevenir o scroll
					$( 'body' ).toggleClass( 'search-open' );
					// Prevenir comportamento por defeito
					event.preventDefault();
				});

				/**
				 * Prevenir comportamento em âncoras vazias
				 */

				$( 'a[href="#"]' ).on( 'click', function( event ) {
					// Prevenir comportamento por defeito
					event.preventDefault();
				});

				/**
				 * Modificar markups dos campos
				 */

				// Seleccionar os selects dos forms
				var $gfieldSelects = $( '.gfield_select' ),
					$gfieldFiles = $( '.ginput_container_fileupload' );

				// Para cada select
				$gfieldSelects.each(function( index, el ) {
					// Iniciar uma nova instância de um select
					var gformSelect = new Select({
						selector: $( this )
					});
					// Construir select
					gformSelect.init();
				});

				// Para cada input file
				$gfieldFiles.each(function( index, el ) {
					// Seleccionar o input
					var $input = $( this ).find( 'input[type="file"]' ),
						inputLabel = $( this ).next( '.gfield_description' ).text();
					// Colocar "botão" novo
					$( '<label for="' + $input.attr( 'id' ) + '" class="file-label button">' + jtmAjaxObject.fileUpload + '</label>' ).insertAfter( $input );
					// Colocar descrição antes do input
					if( typeof inputLabel === 'undefined' || inputLabel === null ) {
						inputLabel = $( this ).siblings( 'label' ).text();
					}
					var $inputDesc = $( '<span class="file-desc">' + inputLabel + '</span>' ).insertBefore( $input );
					// Quando o input mudar de alguma forma
					$input.on( 'change', function( event ) {
						// Buscar o valor do input
						var inputVal = $( this ).val();
						// Se houver um ficheiro escolhido colocar o nome, senão a label
						$inputDesc.text( inputVal.length > 0 ? inputVal.split( '\\' ).pop() : inputLabel );
						// Prevenir comportamento por defeito
						event.preventDefault();
					});
				});

				/**
				 * Telefone
				 */

				// Seleccionar input do telefone
				var $phoneInput = $( '.phone input' );

				// Se houver input de telefone
				if( $phoneInput.length > 0 ) {
				  // Quando se escrever no input do telefone
				  $phoneInput.keyup(function( event ) {
					if (/[^0-9 \+]/g.test(this.value)) {
					  // Remover não-digitos do input
					  this.value = this.value.replace(/[^0-9 \+]/g, '');
					}
				  });
				}

				/**
				 * Carousel Gallery
				 * (Serviços Single, Consultoria, Portfolio)
				 */

				var $carouselGallery = $( '.carousel-gallery' );

				// Se houver um slider
				if( $carouselGallery.length > 0 ) {
					// Criar wrap da navegação do carousel
					var $carouselGalleryNav = $( '<nav class="carousel-gallery-nav"></nav>' ).appendTo( $carouselGallery );
					// Iniciar carousel
					$carouselGallery.slick({
						slide: '.slide',
						rows: 0,
						adaptiveHeight: true,
						centerMode: false,
						centerPadding: 0,
						slidesToShow: 3,
						slidesToScroll: 1,
						dots: true,
						appendDots: $carouselGalleryNav,
						arrows: true,
						prevArrow: '<button type="button" class="slick-prev"></button>',
						nextArrow: '<button type="button" class="slick-next"></button>',
						appendArrows: $carouselGalleryNav,
						responsive: [
							{
								breakpoint: 1280,
								settings: {
									slidesToShow: 2,
									centerMode: false
								}
							},
							{
								breakpoint: 768,
								settings: {
									slidesToShow: 1,
									centerMode: false
								}
							}
						]
					});
				}

				/**
				 * Revelar Objetos
				 */

				// Criar nova instância para revelar objetos
				var revealElements = new WOW({
					boxClass: 'reveal',
					animateClass: 'reveal-animated',
					live: false,
					callback: function( el ) {
						// Quando a animação acabar
						$( el ).one( 'webkitAnimationEnd oanimationend msAnimationEnd animationend', function ( event ) {
							// Colocamos uma class
							$( this ).addClass( 'reveal-active' );
						});
					}
				});

				// Iniciar a instância
				revealElements.init();

				/**
				 * Vídeo
				 */

				// Buscar el do vídeo
				var $video = $( '.video-media.video-js' );

				// Se o vídeo existir
				if( $video.length > 0 ) {
					// Iniciar player
					var videoPlayer = videojs( $video.get(0), {
						autoplay: 'play',
						muted: true,
						controls: false, 
						loop: true,
						preload: true,
						language: 'pt',
						aspectRatio: '16:9'
					});
				}

				/**
				 * Marcadores do Mapa
				 */

				 $( '.addresses-map .addresses-marker' ).popover({
					container: '.addresses-map',
					html: true,
					placement: 'auto',
					trigger: 'focus hover'
				});

				$( '.readmore-collapse' ).on( 'show.bs.collapse hide.bs.collapse', function( event ) {
					$( this ).siblings( 'button' ).html( event.type === 'show' ? jtmAjaxObject.readMoreLabelClose : jtmAjaxObject.readMoreLabelOpen );
				});

				/**
				 * Serviços
				 */

				// Selecionar elemento
				var $stretchedWrap = $( '.stretched-wrapper' );

				// Se o elemento de serviços existir
				if( $stretchedWrap.length > 0 ) {
					// Selecionar slider
					var $stretchedSlider = $stretchedWrap.find( '.stretched-listing' );
					// Quando o slider se iniciar
					$stretchedSlider.on( 'init', function( event, slick ) {
						// Ativamos o primeiro item de navegação
						$stretchedWrap.find( '.stretched-nav a' ).first().addClass( 'active' );
					});
					// Inicializar o slider de serviços
					$stretchedSlider.slick( $.extend( {}, slickDefaults, {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						fade: true
					}));
					// Ao clicar na navegação
					$stretchedWrap.find( '.stretched-nav' ).on( 'click', 'a', function( event ) {
						// Retirar class de ativo a todos os items na navegação
						$( this ).parents( '.stretched-nav' ).find( 'a' ).removeClass( 'active' );
						// Mudar o slider para o slide clicado na navegação em cima
						$stretchedSlider.slick( 'slickGoTo', $stretchedSlider.find( $( this ).attr( 'href' ) ).index() );
						// Adicionar class de ativo na navegação
						$( this ).addClass( 'active' );
						// Prevenir comportamento por defeito
						event.preventDefault();
					});
				}

				/**
				 * Ativar Lightbox
				 */

				 $( '[data-gallery-data]' ).on( 'click', function( event ) {
					// Buscar data
					var data = $( this ).data( 'gallery-data' );
					// Se houver data
					if( typeof data !== 'undefined' && data !== null ) {
						// Criar galeria
						$( this ).lightGallery({
							dynamic: true,
							dynamicEl: data,
							download: false,
							slideEndAnimation: false
						});
						// Prevenir comportamento por defeito
						event.preventDefault();
					}
				});

			}
		},
		'home': {
			init: function() {

				/**
				 * Formulário da Introdução
				 */

				$( document ).on( 'click', '.button-disabled', function() {
					return false;
				});

				$( '.intro-form .gform_button' ).addClass( 'button-disabled' );

				$( '.intro-form' ).on( 'click', 'input[type="radio"]', function() {
					$( this ).parents( '.gform_fields' ).hide().siblings( '.gform_fields' ).show();
					$( this ).parents( 'form' ).find( '.gform_button' ).removeClass( 'button-disabled' );
				});

			}
		},
		'archive': {
			init: function() {

				/**
				 * Slider
				 */

				// Seleccionar elemento
				var $categoryListing = $( '.category-listing' );

				// Se houver categorias
				if( $categoryListing.length > 0 ) {

					// Criar slider
					var $categoryPosts = $categoryListing.find( '.category-posts' ),
						$categoryPostsClone = $categoryPosts.clone( true, true ),
						$categorySlider = $categoryPosts.slick({
							rows: 3,
							slidesPerRow: 4,
							adaptiveHeight: true,
							accessibility: false,
							infinite: false,
							draggable: false,
							dots: false,
							arrows: true,
							prevArrow: '<button type="button" class="slick-arrow slick-prev"><span></span></button>',
							nextArrow: '<button type="button" class="slick-arrow slick-next"><span></span></button>',
							appendArrows: $( '<nav class="slider-nav-controls"/>' ).appendTo( $categoryListing.find( '.category-header' ) ),
							responsive: [
								{
									breakpoint: 992,
									settings: {
										slidesToShow: 2
									}
								},
								{
									breakpoint: 480,
									settings: {
										slidesToShow: 1
									}
								}
							]
						});

					// Quando clicamos nos filtros
					$categoryListing.find( '.sub-category-filters' ).on( 'click', 'a', function( event ) {
						// Buscar id do filtro e remover cardinal
						var category = $( this ).attr( 'href' );
						category = ( category.length < 2 ) ? 'all' : category.substring( 1, category.length );
						// Criar novo slider
						var $newCategorySlider = $categoryPostsClone.clone( true, true );
						// Buscar objeto do slider atual
						var currentSliderObj = $categorySlider.slick( 'getSlick' );
						// Destruir slider
						$categorySlider.slick( 'unslick' );
						// Remover elementos dentro slider
						$categorySlider.empty();
						// Colocar novos sliders
						$categorySlider.append( $newCategorySlider.children( ( category !== 'all' ) ? '[data-child-category=' + category + ']' : '' ) );
						// Se houver já uma nav para inserir os controlos, definir
						if( $( this ).parents( '.category-header' ).find( '.slider-nav-controls' ).length > 0 ) {
							// Re-cache a nova opção com o novo elemento
							currentSliderObj.options.appendArrows = $( this ).parents( '.category-header' ).find( '.slider-nav-controls' );
						}
						// Atualizar classes dos botões
						$( this ).addClass( 'active' ).siblings( 'a' ).removeClass( 'active' );
						// Construir novo slider
						$categorySlider.slick( currentSliderObj.options );
						// Prevenir comportamento por defeito
						event.preventDefault();
					});

				}

			}
		},
		'template_services_single': {
			init: function() {

				/**
				 * Slider da Galeria do Serviço
				 */
				
				$( '.service-gallery-slider' ).slick( $.extend( {}, slickDefaults, {
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
					autoplay: true,
					arrows: true,
					appendArrows: $( '<div class="caret-arrows-nav"></div>' ).appendTo( '.service-gallery-slider' ),
					prevArrow: '<button type="button" class="slick-prev slick-arrow"><i class="fas fa-caret-left"></i></button>',
					nextArrow: '<button type="button" class="slick-next slick-arrow"><i class="fas fa-caret-right"></i></button>'
				}));

			}
		},
		'template_portfolio': {
			init: function() {

				/**
				 * Slider de Galeria de Portfolio
				 */

				var $portfolioGallery = $( '.portfolio-gallery' ).slick( $.extend( {}, slickDefaults, {
					slidesToShow: 3,
					centerMode: true,
					centerPadding: 0,
					arrows: true,
					appendArrows: $( '<div class="caret-arrows-nav"></div>' ).appendTo( '.portfolio-gallery' ),
					prevArrow: '<button type="button" class="slick-prev slick-arrow"><i class="fas fa-caret-left"></i></button>',
					nextArrow: '<button type="button" class="slick-next slick-arrow"><i class="fas fa-caret-right"></i></button>',
					responsive: [
						{ breakpoint: 768, settings: { autoplay: true, slidesToShow: 1, centerMode: false } },
						{ breakpoint: 992, settings: { autoplay: true, slidesToShow: 2, centerMode: false } },
						{ breakpoint: 1200, settings: { autoplay: true } }
					]
				}));

				$portfolioGallery.on( 'setPosition', function( event, slick ) {
					$( slick.options.appendArrows ).css( 'width', slick.slideWidth );
				});

			}
		}
	};

	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = JTM;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery);
